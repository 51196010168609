
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { Post } from "@/types";
import PageHeader from "@/components/ui/PageHeader.vue";
import AdminPanel from "@/components/ui/AdminPanel.vue";
import PostDisplay from "@/components/PostDisplay.vue";

export default defineComponent({
  components: {
    PageHeader,
    AdminPanel,
    PostDisplay,
  },
  data() {
    return {
      store: useStore(),
      post_id: "-1",
      post: {
        post_title: "",
        post_description: "",
        post_content: "",
        post_date: new Date(),
        post_is_public: true,
      } as Post,
    };
  },
  methods: {
    // Fetch post by id from server
    async fetchPostById(id: string) {
      try {
        await this.store.dispatch(`fetchPostById`, id);
        this.post = this.store.getters.getPost;

        if (!this.post) {
          this.$router.push({ name: "NotFound" });
        }
      } catch (error) {
        //console.error("Failed to fetch post by id", error);
      }
    },
    // Delete project methods
    confirmDelete() {
      if (
        confirm("Are you sure you want to delete this post from the database?")
      ) {
        this.deletePostById();
      }
    },
    async deletePostById() {
      try {
        await this.store.dispatch(`deletePostById`, this.post_id);
        // Redirect to posts page after deletion
        this.$router.push("/posts");
      } catch (error) {
        //console.error("Failed to delete post by id", error);
      }
    },
  },

  mounted() {
    this.post_id = this.$route.params.id.toString();
    this.fetchPostById(this.post_id);
  },
});
