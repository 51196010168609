import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AdminPanel = _resolveComponent("AdminPanel")!
  const _component_ProjectDisplay = _resolveComponent("ProjectDisplay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageHeader),
    _createVNode(_component_AdminPanel, null, {
      buttons: _withCtx(() => [
        _createVNode(_component_router_link, {
          class: "col button ms-0 my-0",
          to: `/edit/project/${_ctx.project_id}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(" edit project ")
          ]),
          _: 1
        }, 8, ["to"]),
        _createElementVNode("button", {
          class: "col button my-0",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.confirmDelete && _ctx.confirmDelete(...args)))
        }, " delete project ")
      ]),
      _: 1
    }),
    (_ctx.project)
      ? (_openBlock(), _createBlock(_component_ProjectDisplay, {
          key: 0,
          project: _ctx.project
        }, null, 8, ["project"]))
      : _createCommentVNode("", true)
  ], 64))
}