import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3483a678"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nodes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AdminPanel = _resolveComponent("AdminPanel")!
  const _component_PostNode = _resolveComponent("PostNode")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageHeader),
    _createVNode(_component_AdminPanel, null, {
      buttons: _withCtx(() => [
        _createVNode(_component_router_link, {
          class: "button ms-0 my-0",
          to: `/create/post`
        }, {
          default: _withCtx(() => [
            _createTextVNode(" new post ")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
          return (_openBlock(), _createElementBlock("li", {
            key: post.post_id
          }, [
            _createVNode(_component_PostNode, { post: post }, null, 8, ["post"])
          ]))
        }), 128))
      ])
    ])
  ], 64))
}